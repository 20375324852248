import React from 'react'
import style from './index.module.less'

import { useIntl } from "react-intl"
   
const Intro = () => {
 const intl = useIntl()
    return (
        <div className={style.intro}>
            <div className={style.introCoverTop}></div>
            <div className={style.introCoverTop}></div>
            
            <div className={style.introBox}>
                <p>
                {intl.formatMessage({ id: "about_intro_1", defaultMessage: "XREAL is a tech startup. Like many tech startups, we are a group of energetic people who believe in technology and feel enthusiastic about what tomorrow could bring." })}
                    
                </p>
                <p>
                {intl.formatMessage({ id: "about_intro_2", defaultMessage: "  Every now and then, a new technology comes along and changes everything as we know them. We started the company believing that AR is one of those technologies. We believe that some day AR glasses will become a mainstream consumer electronic product and that AR technologies will enrich the lives of many people." })}
                  
                </p>
                <p>
                {intl.formatMessage({ id: "about_intro_3", defaultMessage: "  Prior to XREAL, AR glasses were bulky, heavy, expensive, and they were designed as silo systems, not able to work with any existing devices users have. XREAL changed all of that. We were the first company to pack world-class AR technologies into a consumer-friendly sunglasses-like form factor. We designed and manufactured our own optic engine to ensure the best image quality. And we built software to allow AR applications to be run on mobile phones." })}  </p>
                <p>
                {intl.formatMessage({ id: "about_intro_4", defaultMessage: "   Since we first announced our product in 2019, XREAL has been fortunate to have received strong endorsements from major consumers, carriers, developers and investors.  Today, XREAL is the largest consumer AR glasses company globally. Thanks to our fans around the world, XREAL has roughly the same market share as #2-#5 combined, according to a 2023 IDC study." })}  
                </p>
            </div>
 
        </div>
    )
}

export default Intro