import * as React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import style from './index.module.less'
import {
  Timeline,
  AllEmployees,
  Intro
} from '@/components/About/index'
import { useInView } from "react-intersection-observer"
import { aboutBg } from '@/images/about/index'
import { useIntl } from "react-intl"

const Index = () => {
  const intl = useIntl()
  const [timelineRef, timelineInView] = useInView({
    threshold: 0,
  })

  const [headerRef, headerRefInView] = useInView({ threshold: 0 });
  const [bodyRef, bodyRefInView] = useInView({ threshold: 0 });

  return (
    <Layout footerBackground='#fff' footerTextWhite={false} menuBlack={!headerRefInView && bodyRefInView ? true : false}>
      <SEO page="About" />
      <div className={style.header} ref={headerRef} style={{ backgroundImage: `url(${aboutBg})` }}>
        {intl.formatMessage({ id: "footerList.about", defaultMessage: "About" })}
      </div>
      <div ref={bodyRef}>
        <Intro />
        <div ref={timelineRef}>
          <Timeline inView={timelineInView} />
        </div>
        <AllEmployees />
      </div>


    </Layout>
  )
}

export default Index
