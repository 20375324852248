import React from "react"
import debounce from "lodash.debounce"

export const useVh = () => {
  const [vh, setVh] = React.useState<number>()
  const [vw, setVw] = React.useState<number>()

  React.useEffect(() => {
    const resizer = debounce(
      () => {
        setVw(window.innerWidth / 100)
        setVh(window.innerHeight / 100)
      }, 200)

    window.addEventListener("resize", resizer)
    resizer()
    return () => {
      window.removeEventListener("resize", resizer)
    }
  }, [setVh, setVw])

  return React.useMemo(() => ({ vh, vw }), [vh, vw])
}

export const useClientWidth = () => {
  const [clW, set] = React.useState(0)

  React.useEffect(() => {
    const resizer = debounce(() => {
      set(document.body.clientWidth)
    }, 16.6666666)

    window.addEventListener("resize", resizer)
    resizer()
    return () => {
      window.removeEventListener("resize", resizer)
    }
  }, [set])

  return clW
}
export const useClientHeight = () => {
  const [clH, set] = React.useState(0)

  React.useEffect(() => {
    const resizer = debounce(() => {
      set(document.body.clientHeight)
    }, 16.6666666)

    window.addEventListener("resize", resizer)
    resizer()
    return () => {
      window.removeEventListener("resize", resizer)
    }
  }, [set])

  return clH
}