import * as React from "react"
import styled from "styled-components"
import { useClientWidth } from "@/hooks/useVh"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { useIntl } from "react-intl"
import style from './index.module.less'
import { ShiXinHeiBtn_updown } from '@/components/index'
import useNavigate from '@/hooks/useNavigate'
import useBigScreen from "@/hooks/bigScreen"


const Wrapper = styled.div`
  min-height: 50vh;
  // padding-top: 80px;
  overflow: hidden;
  text-align:center;
`

const FlexContainer = styled.div`
  width: calc(100% + 20px);
  display: flex;
  //justify-content: flex-start;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  overflow: hidden;
  margin-top:80px;
`

const ProfilePhoto = styled(GatsbyImage)``

const Index = () => {
  
  const intl = useIntl()
  const [allImages, setAllImages] = React.useState([])
  const [divisibleIndex, setDivisibleIndex] = React.useState(0)

  const clientWidth = useClientWidth()

  // 一排能放多少张图，以 宽度 102 来计算
  const count = Math.floor(clientWidth / 102)

  

  React.useEffect(() => {
    fetch(
      `${process.env.resourceUrl}images/about/avatar/avatars.json`
    ).then(response => {
      return response.json();
    }).then(data => {
      // Work with JSON data here
       
      let images = []
      Object.keys(data).forEach((key) => {
        let obj = {
          name: key,
          src: `${process.env.resourceUrl}images/about/avatar/${key}.${data[key] || "jpg"}`
        }
        images.push(obj)
      })

      images.sort(
        (a: { name: string }, b: { name: string }) =>
          parseInt(a.name) - parseInt(b.name)
      )

      // 有多少行
      const lines = Math.floor(images.length / count)

      // 能整除的上限
      const divisibleIndex = count * lines

      setAllImages(images);
      setDivisibleIndex(divisibleIndex);
    })
  }, [clientWidth])

  return (
    <Wrapper>
      <div style={{padding:"0 30px"}}>
         <div className={style.title}>
        {intl.formatMessage({ id: "about.alem.career.oppotunities" })}
      </div>
      <ShiXinHeiBtn_updown onClick={()=>useNavigate('/career')}>
        <div className={style.btn}>
          {intl.formatMessage({ id: "about.alem.more.posi" })}
        </div>
        
      </ShiXinHeiBtn_updown>
      </div>
     

      {/* <FlexContainer>
        {images.map((c, index) => {
          if (index + 1 > divisibleIndex) {
            return ""
          }
          return (
            <ProfilePhoto
              style={{ width: `${100 / count}%` }}
              key={c.node.image.childImageSharp.fluid.src}
              fluid={c.node.image.childImageSharp.fluid}
            />
          )
        })}
      </FlexContainer> */}
         <FlexContainer>
       {allImages.length ? allImages.map((c, index) => {
          if (index + 1 > divisibleIndex) {
            return ""
          }
          return (
            <img
              style={{ width: `${100 / count}%` }}
              key={c.name}
              src={c.src}
              alt={parseInt(c.name).toString()}
            />
          )
        }) : null}
      
      </FlexContainer>
    </Wrapper>
  )
}

export default React.memo(Index)
