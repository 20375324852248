import * as React from "react"
import { animated } from "react-spring"
import style from "./index.module.less"
import { useTransition, config as cf, useTrail } from "react-spring"



export interface ITimeLineItem {
  title: string
  content: string
}


interface ITimeLineBlockProps {
  item: ITimeLineItem
  left: boolean
  styles: any
}

export const OneBlock: React.FC<ITimeLineBlockProps> = ({
  // styles,
  item,
  open
}) => {
  const config = open ? {} : { duration: 0 }
  const trail = open ? 400 : 0
  const nowArr = item
  let transitions =
    // @ts-ignore
    useTransition(
      // open ? timelineData["zh"] : [],
      open ? item : [],
      (item: ITimeLineItem) => item.title,
      {
        config,
        trail,
        from: {
          // contentFilter: "blur(20px)",
          contentOpacity: 1,
          dotTransform: `scale(0)`,
          contentTransform: `scale(0)`,
          headerTransform: `scale(0) rotate(-90deg)`,
          axisHeight: `0%`,
        },
        enter: {
          contentOpacity: 1,
          // contentFilter: "blur(0px)",
          dotTransform: `scale(0.9)`,
          headerTransform: `scale(1)  rotate(-90deg)`,
          axisHeight: `100%`,
          contentTransform: `scale(1) `,
        },
        update: {
          contentOpacity: 1,
          // contentFilter: "blur(0px)",
          dotTransform: `scale(0.9)`,
          axisHeight: `100%`,
          contentTransform: `scale(1) `,
          headerTransform: `scale(1)  rotate(-90deg)`,
        },
        leave: {
          contentOpacity: 0,
          dotTransform: `scale(0)`,
          axisHeight: `0%`,
          contentTransform: `scale(0) `,
          headerTransform: `scale(0) rotate(-90deg)`,

          display: "none"
        },

      }
    )

  return (<>
    {transitions.map(({ item, props, key }, index) =>(
      <div className={style.rightBlock}  key={key}>
        <animated.div className={style.rightAxis}
          style={{  height: props.axisHeight}}
        />
        <animated.div
          className={style.header}
          style={{
            filter: props.contentFilter,
            transform: props.headerTransform,
            opacity: props.contentOpacity,
          }}
        >
          {item.title}
        </animated.div>
        <animated.div
          className={style.main}
          style={{
            filter: props.contentFilter,
            transform: props.contentTransform,
            opacity: props.contentOpacity,

          }}
        >
          {item.content}
        </animated.div>
      
       
        
      </div>
    ))}
   
  </>)

}



export default OneBlock